import { Fabric, FabricCategory } from '@af-common/UIData/_data/Fabrics';
import { Button, Container, Grid } from '@mui/material';
import React, { ReactNode, useCallback } from 'react'
import Immutable from 'immutable'
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import WebsiteStore from 'WebsiteStore';
import { useSelector } from 'react-redux';

type Props = {
    slug: string
	url: string
    img: string
    title: ReactNode
    description: ReactNode
    fabrics: string[]
}

export default function FabricListCategory(props: Props) {
    const [t] = useTranslation('pages/fabrics')
	const [tR] = useTranslation('routes')
    const fabrics = useSelector((state: WebsiteStore) => state.get('UIData').get('fabrics'));

    const renderFabric = useCallback((fabric: Fabric, index: number) => {
		let fabricSize = fabric.get('sizes').find(entry => entry.get('slug') == "meter");
		return <Grid className="fabrics--entry" item key={index} xs={12} sm={6} md={3}>
			<div className="fabrics--entry--image-wrapper">
				<img className="fabrics--entry--image" src={"/static/img/pages/fabric/thumbnails/white/" + fabric.get('slug') + ".jpg"} alt={t('Close up of {{fabric}}', { fabric: fabric.get('name')})} />
				<img className="fabrics--entry--image-printed" src={"/static/img/pages/fabric/thumbnails/printed/" + fabric.get('slug') + ".jpg"} alt={t('Close up of printed {{fabric}}', { fabric: fabric.get('name')})} />
			</div>

			<h4 className="fabrics--entry--title">{fabric.get('name')}</h4>
			<div className="fabrics--entry--subtitle">{fabric.get('info').get('specs').content}</div>

			<div className="fabrics--entry--specs">
				<span className="fabrics--entry--size">
					{/*<span className="fabrics--entry--img"><img src="/static/img/pages/fabric/ruler.svg" alt="Ruler" /></span>*/}
					{t('Width: {{width}}', { width: fabric.get('info').get('specs').width })}
				</span>
				<span className="fabrics--entry--weight">
					{/*<span className="fabrics--entry--img"><img src="/static/img/pages/fabric/kettlebell.svg" alt="Kettlebell" /></span>*/}
					{t('Weight: {{weight}}', { weight: fabric.get('info').get('specs').weight })}
				</span>
			</div>

			<div>
				<div className="fabrics--entry--price">
					<span>{t('1 meter')}</span>
					{ fabric.get('slug') === 'minky' 
						? <span className="price">
							<del style={{marginRight: '10px'}}>{fabricSize.get('price').toDinero().toFormat()}</del>
							<strong style={{color: '#f9aa92'}}>{ fabricSize.get('price').get('currency') === 'CAD' 
								? fabricSize.get('price').toDinero().multiply(0.4571428571428572).toFormat() 
								: fabricSize.get('price').toDinero().multiply(0.42105263157894735).toFormat() }</strong>
						</span>
						: <span className="price">{fabricSize.get('price').toDinero().toFormat()}</span> }
					
				</div>
				<div className="fabrics--entry--price">
					<span>{t('2-10 meters')}</span>
					<span className="price">{fabricSize.get('price').toDinero().multiply(0.75).toFormat()}</span>
				</div>
				<div className="fabrics--entry--price">
					<span>{t('11-49 meters')}</span>
					<span className="price">{fabricSize.get('price').toDinero().multiply(0.65).toFormat()}</span>
				</div>
				<div className="fabrics--entry--price">
					<span>{t('50+ meters')}</span>
					<span className="price">{fabricSize.get('price').toDinero().multiply(0.6).toFormat()}</span>
				</div>
			</div>
			<div className="actions">
				<Link to={props.url+'?f=' + fabric.get('slug')}><Button variant="outlined" className="fabrics--entry--button">{t('Learn more')}</Button></Link>
			</div>
		</Grid>
	}, [])

    return <div className="fabrics--category" id={props.slug}>
        <Container>
            <div className="fabrics--category--headerwrapper">
                <div className="fabrics--category--image" style={{ backgroundImage: "url(" + props.img + ")" }}>
                </div>
                <div className="fabrics--category--content">
                    <h3 className="fabrics--category--title">{props.title}</h3>
                    <p>{props.description}</p>
                </div>
            </div>
            <Grid container spacing={2}>
                {props.fabrics.map((slug, index) => {
                    const fabric = fabrics.find(entry => entry.get('slug') == slug);

                    //Skip if fabric doesn't exist
                    if (!fabric) return;

                    return renderFabric(fabric, index)
                })}
            </Grid>
        </Container>
    </div>
}