import { Container, Grid } from "@mui/material";
import React, { ReactNode, useCallback, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import WebsiteStore from "WebsiteStore";

type Props = {
    active?: boolean,
    slug: string,
    title: ReactNode
    description: ReactNode
    printDescription: ReactNode
    images: {
        front: string
        back: string
        closeup: string
        paper: string
    },
    specs: {
        content: ReactNode
        width: ReactNode
        color: ReactNode
        weight: ReactNode
        shrinkage: ReactNode
        printThrough: ReactNode
    },
    uses: {
        title: ReactNode,
        description: ReactNode,
    }[],
}

export default function FabricInfo(props: Props) {
    const [t] = useTranslation('pages/fabrics');
    const [activeSlide, setActiveSlide] = useState(0)
    const fabric = useSelector((state: WebsiteStore) => state.get('UIData').get('fabrics').find(fabric => fabric.get('slug') === props.slug))

    const prevSlide = useCallback(() => {
        setActiveSlide(value => {
            if (value - 1 < 0) {
                return 3
            } else {
                return value - 1
            }
        })
    }, [])

    const nextSlide = useCallback(() => {
        setActiveSlide(value => {
            if (value + 1 > 3) {
                return 0
            } else {
                return value + 1
            }
        })
    }, [])

    const fabricSize = useMemo(() => fabric?.get('sizes').find(entry => entry.get('slug') == "meter"), [fabric]);

    if (!fabricSize) return null;

    return <section
        className={"fabric-type-content blue-part active"}
        style={{
            display: props.active ? 'block' : 'none'
        }}>
        <Container maxWidth="lg">
            <Grid container spacing={4}>
                <Grid item xs={12} lg={6}>
                    <div className="fabric-specifications">
                        <h2>{props.title}</h2>
                        <p>{props.description}</p>
                        <table>
                            <tbody>
                                <tr>
                                    <td>{t('Content')}</td>
                                    <td>{props.specs.content}</td>
                                </tr>
                                <tr>
                                    <td>{t('Printable width')}</td>
                                    <td>{props.specs.width}</td>
                                </tr>
                                <tr>
                                    <td>{t('Base color')}</td>
                                    <td>{props.specs.color}</td>
                                </tr>
                                <tr>
                                    <td>{t('Weight')}</td>
                                    <td>{props.specs.weight}</td>
                                </tr>
                                <tr>
                                    <td>{t('Shrinkage')}</td>
                                    <td>{props.specs.shrinkage}</td>
                                </tr>
                                <tr>
                                    <td>{t('Print through')}</td>
                                    <td>{props.specs.printThrough}</td>
                                </tr>
                            </tbody>
                        </table>
                        <p>{props.printDescription}</p>
                    </div>
                </Grid>
                <Grid item xs={12} lg={6}>
                    <div className="fabric-slider">
                        <div className="slider-controls">
                            <div className="prev" onClick={prevSlide}></div>
                            <div className="next" onClick={nextSlide}></div>
                        </div>
                        <div className="slides">
                            <div className={"slide-item" + (activeSlide === 0 ? " active" : "")} style={{ backgroundImage: "url('" + props.images.front + "')" }}>
                                <div className="slide-title">{t('Front')}</div>
                            </div>
                            <div className={"slide-item" + (activeSlide === 1 ? " active" : "")} style={{ backgroundImage: "url('" + props.images.back + "')" }}>
                                <div className="slide-title">{t('Back')}</div>
                            </div>
                            <div className={"slide-item" + (activeSlide === 2 ? " active" : "")} style={{ backgroundImage: "url('" + props.images.closeup + "')" }}>
                                <div className="slide-title">{t('White close-up')}</div>
                            </div>
                            <div className={"slide-item" + (activeSlide === 3 ? " active" : "")} style={{ backgroundImage: "url('" + props.images.paper + "')" }}>
                                <div className="slide-title">{t('White paper / White')}</div>
                            </div>
                        </div>
                        <div className="slider-pagination">
                            <ul>
                                <li className={activeSlide === 0 ? "active" : ""}>
                                    <a href="#" onClick={(e) => { e.preventDefault(); setActiveSlide(0); }}></a>
                                </li>
                                <li className={activeSlide === 1 ? "active" : ""}>
                                    <a href="#" onClick={(e) => { e.preventDefault(); setActiveSlide(1); }}></a>
                                </li>
                                <li className={activeSlide === 2 ? "active" : ""}>
                                    <a href="#" onClick={(e) => { e.preventDefault(); setActiveSlide(2); }}></a>
                                </li>
                                <li className={activeSlide === 3 ? "active" : ""}>
                                    <a href="#" onClick={(e) => { e.preventDefault(); setActiveSlide(3); }}></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <Grid container className="fabric-utilities" spacing={4}>
                        <Grid item xs={12}>
                            <h3>{t('Uses :')}</h3>
                            <ul>
                                {props.uses.map((useObj, index) => {
                                    return <li key={index}><strong>{useObj.title}</strong> {useObj.description}</li>
                                })}
                            </ul>
                        </Grid>
                        <Grid item lg={12}>
                            <h3>{t('Pricing :')}</h3>
                            <ul>
                                <li><strong>{t('1 meter:')}</strong> {props.slug === 'minky'
                                    ? <>
                                        <del style={{ marginRight: '5px' }}>{fabricSize.get('price').toDinero().toFormat()}</del>
                                        <strong style={{ color: '#f9aa92' }}>{fabricSize.get('price').get('currency') === 'CAD'
                                            ? fabricSize.get('price').toDinero().multiply(0.4571428571428572).toFormat()
                                            : fabricSize.get('price').toDinero().multiply(0.42105263157894735).toFormat()}</strong>
                                    </>
                                    : fabricSize.get('price').toDinero().toFormat()} {t('per meter')}</li>
                                <li><strong>{t('2 - 10 meters:')}</strong> {fabricSize.get('price').toDinero().multiply(0.75).toFormat()} {t('per meter')}</li>
                                <li><strong>{t('11 - 49 meters:')}</strong> {fabricSize.get('price').toDinero().multiply(0.65).toFormat()} {t('per meter')}</li>
                                <li><strong>{t('50+ meters:')}</strong> {fabricSize.get('price').toDinero().multiply(0.6).toFormat()} {t('per meter')}</li>
                            </ul>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Container>
    </section>
}